<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items-per-page="100"
                        :items="tags"
                        :loading="loading"
                        class="elevation-1"
                        :hide-default-footer="true"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="col-md-4">
                                        <router-link :to="'/tags/create'" class="btn btn-success font-weight-bold btn-sm">
                                            <v-icon left>mdi-plus</v-icon> Thêm
                                        </router-link>
                                    </div>
                                    <div class="col-md-4">
                                        
                                    </div>
                                    
                                    
                                </div>
                            </template>

                            <template v-slot:item.Thumbnail="{ item }">
                                <img :src="item.Thumbnail" style="width: 50px; height: 50px" />
                            </template>
                            <template v-slot:item.Parent_cate="{ item }">
                                {{ item.Parent_cate ? item.Parent_cate.Name : "" }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <router-link :to="`/categories/update/${item.Id}`">
                                    <v-icon small class="mr-2">
                                        mdi-pencil
                                    </v-icon>
                                </router-link>
                                <v-icon small @click="confirm_delete(item)" >
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
    metaInfo: {
        title: 'Từ khoá'
    },
    data() {
        return {
            loading: false,
            options: {},
            headers: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "Tên", sortable: false, value: 'Name'},
                { text: "Slug", sortable: false, value: 'Slug'},
                { text: "Actions", align: 'end', value: 'actions', sortable: false },
            ]
        };
    },
    watch: {
        options: {
            handler () {
                this.get_tags();
            },
            deep: true,
        }
    },
    computed: {
        tags() {
            return this.$store.getters['tags/get_tags'];
        },
    },
    components: {
        
    },
    methods: {
        get_tags() {
            var self    = this;
            self.loading = true;

            this.$store.dispatch('tags/get_all').then(() => {
                self.loading = false;
            }).catch(() => {
                self.loading = false;
            });
        },
        search_tags() {
            const page  = 1;
            const limit = this.$route.query.limit || 10;

            var self    = this;
            self.loading = true;
            var payload = {
                page,
                limit,
                toast: this.$root.$bvToast,
                i18n: this.$i18n,
            };
            if(this.query) {
                payload.query = this.query;
            }
            this.$router.push({
                name: "users-index",
                query: { page, limit }
            }).catch(() => { });

            this.$store.dispatch('users/get_users', payload).then(() => {
                self.loading = false;
            }).catch(() => {
                self.loading = false;
            });
        },
        edit_item(item) {
            console.log("edit item:", item);
            this.$router.push(`categories/update/${item.id}`).catch(() => { });
        },
        confirm_delete(item) {
            console.log("delete item:", item);
            var self = this;
            Swal.fire({
                title: `Xoá nhóm sản phẩm ${item.Name}?`,
                // text: `Xoá nhóm sản phẩm ${item.Name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Có',
                cancelButtonText: 'Không'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.delete_item(item.Id);
                }
            })
        },
        delete_item(id) {
            this.$store.dispatch('categories/delete_category', { id, toast: this.$root.$bvToast }).then(() => {
                this.loading = false;
                this.get_categories();
            }).catch(() => {
                this.loading = false;
            });
        },
        update_page(pagination) {
            const page  = pagination.page;
            const limit = pagination.itemsPerPage;

            this.$router.push({
                name: "categories-index",
                query: { page, limit }
            }).catch(() => { });
            this.get_tags();
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Từ khoá", route: "index" }, { title: "Thêm" } ]);
        this.get_tags();
    }
};
</script>
